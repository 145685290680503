<template>
  <div class="dashboard-container" v-loading="loading">
        <div style="margin-bottom: 20px">
    <el-row>
      <el-col :span="12" align="left">
            <el-button class="el-icon-edit" size="small" type="primary" @click="goback"
              >返回</el-button
            >
          </el-col>
       
      <el-col :span="12" align="right">
          <el-button class="el-icon-edit" type="primary" @click="addOrderAppointment"
            >添加收购计划</el-button
          >
        </el-col>
     
    </el-row>
  </div>
    <!-- 添加或编辑类别 -->
    <el-dialog :title="title" :visible="showDialog" @close="btnCancel">
      <!-- 表单 -->
      <el-form
        ref="perForm"
        :model="purchasePlanDto"
        :rules="rules"
        label-width="120px"
      >
   
        <el-form-item label="计划名称" prop="planTitle">
          <el-input v-model="purchasePlanDto.planTitle" style="width: 50%" />
        </el-form-item>
        <el-form-item label="计划上门日期" prop="planComeDoorDate">
          <el-date-picker
      v-model="purchasePlanDto.planComeDoorDate"
      type="date"
      @change="selectChange1"
      value-format="yyyy-MM-dd"
      placeholder="选择日期">
      
    </el-date-picker>
 
         
        </el-form-item>
      
       
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <!-- -------万恶分割线------- -->

   

    <!-- 主体内容 -->

    <div class="tab">
      <el-table
        :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
        :data="list"
        border
        style="width: 100%"
        ref="list"
        class="emp_table"
        v-if="list"
  
      >
        <el-table-column
          align="center"
          prop="planTitle"
          label="计划标题"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          align="center"
          prop="planComeDoorTime"
          label="上门时间"
          :show-overflow-tooltip="true"
       >
        <template slot-scope="scope">
            {{ scope.row.planComeDoorTime | getTime }}
          </template>
        </el-table-column>
        <el-table-column label="销售状态" align="center" >
          <template
            slot-scope="scope"
          >{{scope.row.purchaseInfoVo.purchaseStatus==100?'未开始':scope.row.purchaseInfoVo.purchaseStatus==101?'进行中':scope.row.purchaseInfoVo.purchaseStatus==102?'已完成':scope.row.purchaseInfoVo.purchaseStatus==103?'已取消':''}}</template>
        </el-table-column>
       
        <el-table-column
          align="center"
          prop="createDate"
          label="创建时间"
          width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.createDate | getLocalTime }}
          </template>
        </el-table-column>
    

        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="gotosg(scope.row)"
            >
              收购信息
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="updateManagerUser(scope.row)"
            >
              编辑
            </el-button>
          
            <el-button type="text" size="small" :disabled='scope.row.purchaseInfoVo.purchaseStatus!=100' @click="delServiceAppointment(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getOrderAppointment"
      />
    </div>
  </div>
</template>
  
  <script>

import {
 
  getOrderAppointment,
  delServiceAppointment,
  addOrderAppointment,
  updateOrderAppointment,
} from "../../request/http";

export default {
  data() {
    return {
     
      title: "",
      showDialog: false,
      list: "",
      total: 0,
      loading:true,//加载动画
      queryParams: {
        orderId: "",
        
      },

      dialogVisible: false, //显示添加或编辑框
      purchasePlanDto: {
        orderId: "",
        planComeDoorDate:'',
        planTitle: "",
         
      },

        rules: {
          planComeDoorDate: [
          { required: true, message: "上门日期不能为空", trigger: "blur" },
          ],
          planTitle: [
          { required: true, message: "计划名称不能为空", trigger: "blur" },
         
        ],

      
      },
    };
  },

  created() {
    this.queryParams.orderId = this.$route.query.orderId
   this.purchasePlanDto.orderId=this.$route.query.orderId
   
    this.getOrderAppointment();
  },

  mounted() {},
  methods: {
    goback() {
      this.$router.go(-1)
        },
      
    gotosg(row){
       console.log(row);
       row =JSON.stringify(row)
       this.$router.push({
        path: "purchase",
        query: { row },
      });
    },
    
     
    
      selectChange1(value) {
    this.purchasePlanDto.planComeDoorDate=value
    console.log(this.purchasePlanDto.planComeDoorDate);
  },
    //获取收购列表
    async getOrderAppointment() {
      let { data } = await getOrderAppointment(this.queryParams.orderId);
      let list = data.data;
      console.log(list);
      this.list = list;

      this.total = list.length;
      this.loading = false
    },

    // 打开添加的窗口
    addOrderAppointment() {
      this.title = "添加收购计划";

      this.showDialog = true;
    },
    // 打开编辑类别的窗口
    updateManagerUser(val) {
      // 根据获取id获取详情
      this.title = "编辑收购计划";

      console.log(val);
        this.purchasePlanDto = {
          planId:val.planId,
          orderId: this.$route.query.orderId,
          planComeDoorDate: uptime(val.planComeDoorTime),
          planTitle: val.planTitle,
         
      };
      this.showDialog = true;
    },
    // 确认
    async btnOK() {
      this.$refs.perForm.validate().then(async () => {
        if (this.purchasePlanDto.planId) {
          let { data } = await updateOrderAppointment(this.purchasePlanDto);
          if (this.purchasePlanDto.planId && data.code == 0) {
            this.$message.success("编辑成功");
          } else {
            console.log(data.code);
            this.$message({
              type: "error",
              message: data.code,
            });
          }
          this.getOrderAppointment();

          this.showDialog = false;
        } else {
          let { data } = await addOrderAppointment(this.purchasePlanDto);
          console.log(data);
          if (data.code == 0) {
            this.$message.success("新增成功");
          } else {
            console.log(data.msg);
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
          this.getOrderAppointment();

          this.showDialog = false;
        }
      });
    },
    //弹窗取消函数
    btnCancel() {
      this.purchasePlanDto = {
        orderId: this.$route.query.orderId,
      };
      this.$refs.perForm.resetFields();
      this.showDialog = false;
    },

    async delServiceAppointment(row) {
      try {
        await this.$confirm("确定要删除该收购计划吗");
        console.log(row);
        let planId = row.planId;
        let { data } = await delServiceAppointment(planId);

        if (data.code == 0) {
          this.$message({
            type: "success",
            message: "删除成功",
          });
        } else {
          this.$message({
            type: "error",
            message: data.msg,
          });
        }
        const totalPage = Math.ceil(
          (this.total - 1) / this.queryParams.pageSize
        );
        this.queryParams.pageNum =
          this.queryParams.pageNum > totalPage ? totalPage : this.queryParams.pageNum;
        this.queryParams.pageNum =
          this.queryParams.pageNum < 1 ? 1 : this.queryParams.pageNum;

        this.getOrderAppointment();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
function uptime(nS) {
  let date = new Date(nS);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
 

  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;
  date = year + '-' + month + '-' + day 
  // 2018-10-09
  return date;
}
</script>
  
  <style scoped>
</style>
  